class BlurbCardIT extends BATComponent {
	/* Lifecycle event callbacks */
	beforeLoad() {
		// console.log('BlurbCard before load');
		const componentObject = this.data;
		const text = componentObject.text;
		let textEl = $(text);
		let price;
		let priceFull;
		textEl.each((index, el) => {
			const priceEl = $(el);
			if(priceEl.hasClass('price')) {
				price = priceEl.text();	
			} else if(priceEl.hasClass('price-full')) {
				priceFull = priceEl.text();
			}
		});

		textEl = textEl.filter((index, el) => {
			return !$(el).hasClass('price') && !$(el).hasClass('price-full');
		});

		componentObject.text = '';
		textEl.each((index, el) => {
			const textValue = $(el).prop('outerHTML');
			if(textValue) {
				componentObject.text += textValue;
			}
		});
		componentObject.price = price;
		componentObject.priceFull = priceFull;

		$(this).attr('data-model', JSON.stringify(componentObject));
	}

	afterLoad() {
		// console.log('BlurbCard After load');
		this.$el = $(this);
		this.data.loggedIn = Utils.getCookie('commerce-auth-verify') === 'true';

		if($(this).hasClass("set-copy-for-loggedin")){
			if(this.data.loggedIn){
				document.querySelector(".set-copy-for-loggedin .bat-blurbs-gloit--blurb-main .bat-card--blurb-title span").innerHTML ="PORTA UN AMICO,<br> SCOPRI I VANTAGGI"
				document.querySelector(".set-copy-for-loggedin .bat-blurbs-gloit--blurb-main .bat-card--blurb-andmore p span").innerHTML ="Acquistate entrambi HYPER PRO e scopri come ottenere il vostro omaggio."
				document.querySelector(".set-copy-for-loggedin .bat-blurbs-gloit--blurb-main .bat-blurbs-gloit--blurb-ctalist span").innerHTML ="Scopri di più"
				document.querySelector(".set-copy-for-loggedin .bat-blurbs-gloit--blurb-main .bat-blurbs-gloit--blurb-ctalist a").href="/it/it/blog/post/porta-un-amico-scopri-i-vantaggi-per-voi"
				document.querySelector(".set-copy-for-loggedin .bat-blurbs-gloit--blurb-img  a").href="/it/it/blog/post/porta-un-amico-scopri-i-vantaggi-per-voi"
				document.querySelector(".set-copy-for-loggedin .bat-blurbs-gloit picture img").setAttribute("src","/content/dam/glo-it/images/Registrazione_dispositivo_tall_tile_03-24.webp")
				document.querySelector(".set-copy-for-loggedin .bat-blurbs-gloit picture source").setAttribute("srcset","/content/dam/glo-it/images/Registrazione_dispositivo_tall_tile_03-24.webp")
			}
		}
	}

	beforeUpdate() {
		// console.log('BlurbCard before update');
	}

	afterUpdate() {
		// console.log('BlurbCard after update');
		
	}

	unload() {
		// console.log('BlurbCard after unload');
	}
}

!customElements.get('bat-card-gloitblurb') &&
	customElements.define('bat-card-gloitblurb', BlurbCardIT);
